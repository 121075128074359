// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import './core/polyfill';
import '/root/workspace/aismile-compatible_zTYF/src/global.less';
import '/root/workspace/aismile-compatible_zTYF/src/global.tsx';
import { renderClient } from '/root/workspace/aismile-compatible_zTYF/node_modules/.pnpm/@umijs/renderer-react@4.3.24_react-dom@18.3.1+react@18.3.1/node_modules/@umijs/renderer-react';
import { getRoutes } from './core/route';
import { createPluginManager } from './core/plugin';
import { createHistory } from './core/history';
import Loading from '/root/workspace/aismile-compatible_zTYF/src/loading.tsx';
import { ApplyPluginsType } from 'umi';
import { genMount as qiankun_genMount, genBootstrap as qiankun_genBootstrap, genUnmount as qiankun_genUnmount, genUpdate as qiankun_genUpdate } from '@@/plugin-qiankun-slave/lifecycles';

const publicPath = "/";
const runtimePublicPath = true;

async function render() {
  const pluginManager = createPluginManager();
  const { routes, routeComponents } = await getRoutes(pluginManager);

  // allow user to extend routes
  await pluginManager.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: {
      routes,
      routeComponents,
    },
  });

  const contextOpts = pluginManager.applyPlugins({
    key: 'modifyContextOpts',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });

  const basename = contextOpts.basename || '/compatible';
  const historyType = contextOpts.historyType || 'browser';

  const history = createHistory({
    type: historyType,
    basename,
    ...contextOpts.historyOpts,
  });

  return (pluginManager.applyPlugins({
    key: 'render',
    type: ApplyPluginsType.compose,
    initialValue() {
      const context = {
        routes,
        routeComponents,
        pluginManager,
        mountElementId: 'root-compatible',
        rootElement: contextOpts.rootElement || document.getElementById('root-compatible'),
        loadingComponent: Loading,
        publicPath,
        runtimePublicPath,
        history,
        historyType,
        basename,
        __INTERNAL_DO_NOT_USE_OR_YOU_WILL_BE_FIRED: {"pureApp":false,"pureHtml":false},
        callback: contextOpts.callback,
      };
      const modifiedContext = pluginManager.applyPlugins({
        key: 'modifyClientRenderOpts',
        type: ApplyPluginsType.modify,
        initialValue: context,
      });
      return renderClient(modifiedContext);
    },
  }))();
}


render();

    if (typeof window !== 'undefined') {
      window.g_umi = {
        version: '4.3.24',
      };
    }
    

const qiankun_noop = () => new Error('qiankun lifecycle is not available for server runtime!');
const isServer = typeof window === 'undefined';
export const bootstrap = isServer ? qiankun_noop: qiankun_genBootstrap(render);
export const mount = isServer ? qiankun_noop : qiankun_genMount('root-compatible');
export const unmount = isServer ? qiankun_noop : qiankun_genUnmount('root-compatible');
export const update = isServer ? qiankun_noop : qiankun_genUpdate();
// 增加 ssr 的判断
if (!isServer && !window.__POWERED_BY_QIANKUN__) {
  bootstrap().then(mount);
}
